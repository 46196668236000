import React, { memo, useState } from 'react';

import { Modal } from '@printi/printi-components';
import PropTypes from 'prop-types';

import { INITIAL_INFOS_MODAL } from './contactFormOptions';
import HubspotContactForm from './HubspotContactForm';
import useFetchContact from './useFetchContact';

const ModalContactForm = ({ close }) => {
  const [infosModal] = useState(INITIAL_INFOS_MODAL);
  const [isFormValid] = useState(false);
  const { sendContactIsLoading } = useFetchContact();
  const { buttonName, hideCancelButton, size } = infosModal;

  return (
    <Modal
      size={size}
      buttonName={buttonName}
      hideCancelButton={hideCancelButton}
      close={close}
      isDisabled={!isFormValid}
      isLoading={sendContactIsLoading}
    >
      <HubspotContactForm />
    </Modal>
  );
};

ModalContactForm.propTypes = {
  close: PropTypes.func.isRequired,
};

export default memo(ModalContactForm);
