import { theme } from '@printi/printi-components';
import styled from 'styled-components';
const { color, space, font } = theme;

export const ContactContainer = styled.div`
  font-size: 1.2rem;
  line-height: 2.2rem;
`;

export const ContactIcon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: ${space.md};
  width: 100%;

  svg {
    width: 15.5rem;
    fill: ${color.primary.pure};
    height: 6rem;
  }
`;

export const ContactContent = styled.div`
  margin-top: 0.8rem;

  p {
    margin: 0;
    padding: 0.2rem;
    font-size: 1rem;
    font-weight: 400;
    font-family: ${font.family.text};
    text-align: center;
  }
  h1 {
    font-size: ${font.size.h5};
    letter-spacing: -0.02em;
    color: ${color.dark.pure};
    font-family: ${font.family.text};
    font-style: normal;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1rem;
  }
  h5 {
    margin-top: 1rem;
    text-align: center;
  }
  h6 {
    font-size: ${font.size.small};
  }
`;

export const Button = styled.div`
  margin: 6% 0 2% 26%;

  button {
    color: ${color.dark.pure};
    font-family: ${font.family.text};
    font-weight: ${font.weight.bold};
    font-size: ${font.size.h6};
    line-height: ${font.lineHeight.small};
  }
`;
