import { useCallback, useState } from 'react';

import { api, generateRequestBody } from '@utils';

const useFetchContact = () => {
  const [sendContactIsLoading, setSendContactIsLoading] = useState(false);

  const sendContact = useCallback(async (contactData, type = 'corporate', setInfosModal) => {
    setSendContactIsLoading(true);

    const body = generateRequestBody(contactData);

    await api
      .post('/v1/leads', {
        ...body,
        leadsource: type,
        locale: 'BR',
      })
      .then(() => {
        setInfosModal({
          title: 'SUCESSO!',
          buttonName: 'OK',
          hideCancelButton: true,
          size: 'small',
          message: 'Seus dados foram enviados com sucesso.',
        });
      })
      .catch(() => {
        setInfosModal({
          title: 'ERRO!',
          buttonName: 'OK',
          hideCancelButton: true,
          size: 'small',
          message: 'Ocorreu um erro ao tentar enviar os dados.',
        });
      });

    setSendContactIsLoading(false);
  }, []);

  return { sendContact, sendContactIsLoading };
};

export default useFetchContact;
