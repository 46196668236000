import React from 'react';

import { Icon, Text } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './styles';

const CardOptions = ({ icon, name, content, handleClickOptions, hasChildren, children }) => (
  <S.ColOptionsCard xs={12} md={3} style={{ marginBottom: '16px' }}>
    <S.OptionsCard
      tabIndex="0"
      onClick={() => handleClickOptions(name)}
      onKeyUp={({ code }) => code === 'Enter' && handleClickOptions(name)}
    >
      <S.ContainerCard>
        <S.Icon>{icon}</S.Icon>
        <S.Name>{name}</S.Name>

        <S.IconArrow>
          <Icon color="#009BFF" name="ArrowIosForward" />
        </S.IconArrow>
      </S.ContainerCard>
      <S.Content>
        {hasChildren && children()}
        {!hasChildren && <Text dangerouslySetInnerHTML={{ __html: content }} />}
      </S.Content>
    </S.OptionsCard>
  </S.ColOptionsCard>
);

CardOptions.propTypes = {
  icon: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  handleClickOptions: PropTypes.func,
};

export default CardOptions;
