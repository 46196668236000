import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Col, Text, Icon } from '@printi/printi-components';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import PropTypes from 'prop-types';

import IconWhatsapp from '../ButtonWhatsApp/icon';
import CardOptions from './chanellOptions';
import { BallonChat, Corporate, TeleSales, Doubts } from './icons';
import SalesCloudLiveChat from './SalesCloudLiveChat';
import * as S from './styles';

const ModalContactForm = dynamic(() => import('@components/ModalContactForm'));
const ModalGetInTouch = dynamic(() => import('./ModalGetInTouch'));

const whatsAppUrlSuporte = 'https://api.whatsapp.com/send?phone=551141183816';
const whatsappNumber = '(11) 4118-3816';

const ChannelOptions = ({ iconName, showHelpCenterLink }) => {
  const [phoneCallModalIsOpen, setPhoneCallModalIsOpen] = useState(false);
  const [contactModalIsOpen, setContactModalIsOpen] = useState(false);

  const { locale } = useSelector((state) => state.locale);
  const sales_cloud = locale.snippets_configuration.critical.sales_cloud[locale.COUNTRY_CODE];

  const optionsData = [
    {
      icon: <BallonChat width="32" height="32" fill="none" />,
      name: 'SAC',
      hasChildren: true,
      children: () => (
        <>
          <Text>
            Conte com o nosso WhatsApp!
            <S.TelWrapper>
              <S.TelWrapperIcon>
                WhatsApp:
                <strong>
                  <Text as="a" color="primary" href={whatsAppUrlSuporte}>
                    (11) 4118-3816
                  </Text>
                </strong>
              </S.TelWrapperIcon>
            </S.TelWrapper>
            Horário de Atendimento: <br /> <strong>Segunda a Sexta</strong> 9:00 as 18:00h.
          </Text>
        </>
      ),
    },
    {
      icon: <TeleSales width="32" height="32" fill="none" />,
      name: 'Televendas',
      hasChildren: true,
      children: () => (
        <>
          <Text>
            <S.TelWrapper>
              <S.TelWrapperIcon>
                <Icon name="Phone" size="sm" /> (11) 4118-3820
              </S.TelWrapperIcon>
              <S.TelWrapperIcon>
                <IconWhatsapp size={14} /> {whatsappNumber}
              </S.TelWrapperIcon>
            </S.TelWrapper>
            <strong>Segunda a sexta</strong> das 9h às 18h <br /> vendas@printi.com.br
          </Text>
        </>
      ),
    },
    {
      icon: <Corporate width="32" height="32" fill="none" />,
      name: 'Corporativo',
      content: 'Solicite seu atendimento corporativo com um de nossos Gerentes de Contas.',
    },
  ];

  const handleChat = () => {
    window?.HubSpotConversations.widget.load({ widgetOpen: false });
  };

  const handleCorporate = () => {
    setContactModalIsOpen(true);
  };

  const handlePhoneCall = () => {
    setPhoneCallModalIsOpen(true);
  };

  const handleClickOptions = (name) => {
    switch (name) {
      case 'Corporativo':
        handleCorporate();
        break;
      case 'Televendas':
        handlePhoneCall();
        break;
      case 'Chat':
        handleChat();
        break;
      default:
        break;
    }
  };

  const centralDeAjuda = 'central-de-ajuda';
  const helpCenterUrl = `${process.env.PRINTI_URL}/${centralDeAjuda}`;

  return (
    <>
      <S.OptionsCardContainer>
        {optionsData.map((card) => (
          <CardOptions key={card.name} {...card} handleClickOptions={() => handleClickOptions(card.name)} />
        ))}
        {showHelpCenterLink && (
          <Col xs={12}>
            <S.TextWithIcon>
              <Doubts width="24" height="24" fill="none" />
              Se preferir, acesse nossa &nbsp;
              <Link target="_blank" href={helpCenterUrl}>
                Central de ajuda
              </Link>
            </S.TextWithIcon>
          </Col>
        )}
      </S.OptionsCardContainer>
      <SalesCloudLiveChat s={sales_cloud} />
      {phoneCallModalIsOpen && (
        <ModalGetInTouch Icon={iconName} close={() => setPhoneCallModalIsOpen(false)}>
          <Text as="h1">
            <b>Fale com Televendas!</b>
          </Text>
          <Text>
            <b>Televendas: </b>
            <Text as="a" color="primary" href="tel:(11) 4118-3820">
              (11) 4118-3820
            </Text>
          </Text>
          <Text>
            <b>Whatsapp: </b>
            <Text as="a" color="primary" href={whatsAppUrlSuporte}>
              {whatsappNumber}
            </Text>
          </Text>
          <Text>
            <b>Email: </b>
            <Text as="a" color="primary" href="mailto:vendas@printi.com.br">
              vendas@printi.com.br
            </Text>
          </Text>
          <Text as="h5">
            <b>Horário de atendimento:</b>
            <Text as="h6">Segunda à Sexta-feira das 09h00 às 18h00</Text>
          </Text>
        </ModalGetInTouch>
      )}
      {contactModalIsOpen && (
        <ModalContactForm close={() => setContactModalIsOpen(false)} type="contact" hideFields={['occupation_area']} />
      )}
    </>
  );
};

ChannelOptions.propTypes = {
  iconName: PropTypes.string,
  showHelpCenterLink: PropTypes.bool,
};
export default memo(ChannelOptions);
