import React, { memo } from 'react';

import { Modal, Button } from '@printi/printi-components';
import PropTypes from 'prop-types';

import * as S from './ModalGetInTouch.styles';

const ModalGetInTouch = ({ close, children }) => (
  <Modal size="small" infos={{ title: '' }} showActions={false} hideCancelButton close={close} func={close}>
    <S.ContactContainer>
      <S.ContactContent>{children}</S.ContactContent>
    </S.ContactContainer>
    <S.Button>
      <Button tabIndex="0" onClick={() => close()}>
        OK
      </Button>
    </S.Button>
  </Modal>
);

ModalGetInTouch.propTypes = {
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default memo(ModalGetInTouch);
