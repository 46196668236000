const properties = {
  value: '',
  error: '',
  hasError: true,
  dirty: false,
};

export const INITIAL_CONTACT = {
  email: { ...properties },
  first_name: { ...properties },
  last_name: { ...properties },
  phone: { ...properties },
  empresa: { ...properties },
  website: { ...properties },
  spending: { ...properties },
  position: { ...properties },
  occupation_area: { ...properties },
};

export const INITIAL_INFOS_MODAL = {
  title: 'QUER SABER MAIS?',
  buttonName: 'Enviar',
  hideCancelButton: false,
  size: 'large',
  message: '',
};

export const SPENDING_OPTIONS = [
  { id: 5000, name: 'Até R$ 5.000 ao mês' },
  { id: 10000, name: 'De R$ 5.001 a R$ 10.000 ao mês' },
  { id: 50000, name: 'De R$ 10.001 a R$ 50.000 ao mês' },
  { id: 100000, name: 'De R$ 50.001 a R$ 100.000 ao mês' },
  { id: 100001, name: 'Mais de R$ 100.000 ao mês' },
];

export const POSITION_OPTIONS = [
  { id: 1016, name: 'Assistente' },
  { id: 1017, name: 'Auxiliar' },
  { id: 1018, name: 'Analista' },
  { id: 1019, name: 'Coordenador/Supervisor' },
  { id: 1020, name: 'Office Manager' },
  { id: 1021, name: 'Director' },
  { id: 1022, name: 'Outros' },
];

export const OCCUPATION_AREA_OPTIONS = [
  { id: 'print_shop', name: 'Gráfica' },
  { id: 'graphic_reseller', name: 'Revendedor Gráfico' },
  { id: 'agency', name: 'Agência de comunicação/publicidade/conteúdo/criação' },
  { id: 'designer', name: 'Designer' },
  { id: 'printi_product_dealer', name: 'Revendedor de produtos oferecidos pela Printi' },
];

export const allFields = [
  'email',
  'first_name',
  'last_name',
  'phone',
  'empresa',
  'website',
  'spending',
  'position',
  'occupation_area',
];
