import React from 'react';

import PropTypes from 'prop-types';

export const BallonChat = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 34 34" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4348 13.6538C14.2418 13.6538 13.276 14.6432 13.276 15.8654V25.5962C13.276 26.8183 14.2418 27.8077 15.4348 27.8077H22.3427C22.5984 27.8077 22.8484 27.8852 23.0612 28.0305L26.2284 30.1937V29.1346C26.2284 28.4018 26.8083 27.8077 27.5236 27.8077H29.2506C30.4436 27.8077 31.4094 26.8183 31.4094 25.5962V15.8654C31.4094 14.6432 30.4436 13.6538 29.2506 13.6538H15.4348ZM10.6855 15.8654C10.6855 13.1775 12.8111 11 15.4348 11H29.2506C31.8743 11 33.9998 13.1775 33.9998 15.8654V25.5962C33.9998 28.284 31.8743 30.4615 29.2506 30.4615H28.8189V32.6731C28.8189 33.1624 28.556 33.6121 28.1348 33.843C27.7137 34.0739 27.2026 34.0486 26.8052 33.7771L21.9505 30.4615H15.4348C12.8111 30.4615 10.6855 28.284 10.6855 25.5962V15.8654Z"
      fill="#009BFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 5.34258C0 2.39164 2.40744 0 5.37786 0H21.8221C24.7926 0 27.2 2.39164 27.2 5.34258V12.1613C27.2 12.8561 26.633 13.4194 25.9336 13.4194C25.2342 13.4194 24.6673 12.8561 24.6673 12.1613V5.34258C24.6673 3.78126 23.3938 2.51613 21.8221 2.51613H5.37786C3.80623 2.51613 2.53275 3.78126 2.53275 5.34258V16.5746C2.53275 18.1359 3.80623 19.401 5.37786 19.401H7.43276C8.13216 19.401 8.69914 19.9643 8.69914 20.6591V22.3915L10.695 21.0699C11.277 20.6846 12.0632 20.8409 12.4511 21.419C12.839 21.9972 12.6817 22.7783 12.0997 23.1636L8.13512 25.7888C7.74652 26.0461 7.2469 26.07 6.83516 25.8511C6.42342 25.6322 6.16639 25.2059 6.16639 24.7419V21.9172H5.37786C2.40744 21.9172 0 19.5255 0 16.5746V5.34258Z"
      fill="#009BFF"
    />
  </svg>
);

BallonChat.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
};

export const TeleSales = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 35 35" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1655 20.6929C12.1667 18.6942 10.66 16.4562 9.65888 14.1859C9.44705 13.7058 9.57175 13.1438 9.94246 12.7731L11.3416 11.3739C12.4879 10.2277 12.4879 8.60644 11.4868 7.60536L9.48121 5.60149C8.147 4.26728 5.98425 4.26728 4.65005 5.60149L3.53621 6.71361C2.27034 7.97949 1.74246 9.80569 2.08413 11.6165C2.92805 16.0804 5.5213 20.9679 9.705 25.1517C13.8887 29.3354 18.7763 31.9286 23.2401 32.7725C25.051 33.1142 26.8772 32.5863 28.143 31.3204L29.2552 30.2083C30.5894 28.8741 30.5894 26.7114 29.2552 25.3772L27.2513 23.3733C26.2502 22.3722 24.6273 22.3722 23.6279 23.3733L22.0853 24.9176C21.7146 25.2883 21.1525 25.413 20.6725 25.2012C18.4021 24.1984 16.1642 22.6899 14.1655 20.6929Z"
      stroke="#009BFF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="#FFF"
    />
    <path
      d="M19.071 5.83222V1.83472"
      stroke="#009BFF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="#009BFF"
    />
    <path
      d="M26.1953 8.65103L29.0141 5.84937"
      stroke="#009BFF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="#009BFF"
    />
    <path
      d="M29.0312 15.7919H33.0117"
      stroke="#009BFF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="#009BFF"
    />
  </svg>
);

TeleSales.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
};

export const Corporate = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 39 28" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.44444 20.7273C5.26971 20.7273 2.66667 23.3895 2.66667 26.6364C2.66667 27.3895 2.06971 28 1.33333 28C0.596954 28 0 27.3895 0 26.6364C0 21.8832 3.79695 18 8.44444 18H15.5556C20.203 18 24 21.8832 24 26.6364C24 27.3895 23.403 28 22.6667 28C21.9303 28 21.3333 27.3895 21.3333 26.6364C21.3333 23.3895 18.7303 20.7273 15.5556 20.7273H8.44444Z"
      fill="#009BFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8936 4.10014C13.8571 2.2152 10.6519 2.19174 8.93943 4.04702C7.15803 5.97697 7.24113 8.92135 9.11639 10.953C10.8333 12.8131 13.8753 12.7853 15.9117 10.8831C17.798 9.12097 17.8551 6.09147 15.8936 4.10014ZM17.7115 2.15422C14.7915 -0.567287 9.87559 -0.891491 6.99433 2.23002C4.17484 5.28462 4.44565 9.81705 7.17129 12.77C10.0553 15.8944 14.7994 15.5661 17.7178 12.8399C20.7787 9.98059 20.7289 5.19444 17.7593 2.20053C17.7516 2.1927 17.7438 2.18493 17.7358 2.17725C17.7278 2.16945 17.7197 2.16177 17.7115 2.15422Z"
      fill="#009BFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 16.3333C25 15.597 25.6268 15 26.4 15H32C35.9465 15 39 17.9081 39 21.6667C39 22.403 38.3732 23 37.6 23C36.8268 23 36.2 22.403 36.2 21.6667C36.2 19.3808 34.4001 17.6667 32 17.6667H26.4C25.6268 17.6667 25 17.0697 25 16.3333Z"
      fill="#009BFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.5683 3.69938C32.3236 2.48942 30.395 2.56707 29.3931 3.67293C29.3772 3.69039 29.361 3.70739 29.3444 3.72391C28.2908 4.77044 28.2588 6.86112 29.4392 8.30876C30.3874 9.47158 32.2816 9.50693 33.5933 8.20405C34.6967 7.10796 34.7749 5.05868 33.5683 3.69938ZM35.2923 1.6209C33.1399 -0.507989 29.6669 -0.583673 27.6118 1.6544C25.289 3.99189 25.6654 7.89263 27.5417 10.1937C29.657 12.7877 33.2088 12.3766 35.301 10.2984C37.5913 8.02335 37.5224 4.08843 35.358 1.68976C35.3475 1.67792 35.3367 1.66624 35.3257 1.65472C35.3147 1.64319 35.3035 1.63191 35.2923 1.6209Z"
      fill="#009BFF"
    />
  </svg>
);
Corporate.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
};

export const Doubts = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0002 13.204V12.964C12.0002 12.177 12.4862 11.751 12.9742 11.424C13.4502 11.104 13.9272 10.686 13.9272 9.91501C13.9272 8.85101 13.0652 7.98901 12.0012 7.98901C10.9372 7.98901 10.0742 8.84901 10.0742 9.91301"
      stroke="#2C2C3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.999 15.919C11.903 15.919 11.825 15.997 11.826 16.093C11.826 16.189 11.904 16.267 12 16.267C12.096 16.267 12.174 16.189 12.174 16.093C12.174 15.996 12.096 15.919 11.999 15.919"
      stroke="#2C2C3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.2567 15.128L20.0437 16.021C20.6507 14.81 21.0007 13.447 21.0007 12C21.0007 7.029 16.9717 3 12.0007 3C9.7977 3 7.7827 3.794 6.2207 5.11"
      stroke="#2C2C3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.744 8.87203L3.957 7.97803C3.35 9.19003 3 10.553 3 12C3 16.97 7.029 21 12 21C14.203 21 16.218 20.206 17.78 18.89"
      stroke="#2C2C3A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Doubts.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
};
