import { Text, Col } from '@printi/printi-components';
import styled from 'styled-components';

export const OptionsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const ColOptionsCard = styled(Col)`
  max-width: calc(33% - 16px);
  flex-grow: 1;
  padding: 0;

  @media screen and (min-width: 992px) {
    max-width: calc(33% - 16px);
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;
export const OptionsCard = styled.div`
  background-color: #fff;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  border-radius: 4px;
  min-width: 0;
  height: 100%;
  cursor: pointer;

  @media screen and (min-width: 768px) and (max-width: 885px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const ContainerCard = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
`;

export const Icon = styled.div`
  margin-right: 8px;

  @media screen and (min-width: 768px) and (max-width: 885px) {
    svg {
      width: 20px;
    }
  }
`;
export const Name = styled.h4`
  align-items: center;
  display: flex;
  font-family: 'Circular';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: #2c2c3a;
  margin: 0;
  flex: 1;
`;

export const IconArrow = styled.div`
  svg {
    width: 24px;
    height: 24px;
    margin-bottom: 0;
  }
  @media screen and (min-width: 768px) and (max-width: 885px) {
    svg {
      width: 20px;
    }
  }
`;
export const Content = styled.div`
  p {
    font-family: 'Circular';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    color: #2c2c3a;
  }
  strong {
    font-family: 'Circular';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    color: #2c2c3a;
  }
  @media screen and (max-width: 320px) {
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
    }
    strong {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }
  }
`;
export const TextWithIcon = styled(Text)`
  display: flex;
  align-items: center;
  margin: 16px 0 68px 0;
  p {
    font-family: 'Circular';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: #2c2c3a;
  }
  svg {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    fill: none;
  }
  a {
    color: #009bff;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-decoration-line: underline;
    font-family: 'Circular';
  }
  @media screen and (max-width: 320px) {
    p {
      font-size: 10px;
    }
    a {
      font-size: 12px;
    }
    svg {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }
  }
`;

export const TelWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const TelWrapperIcon = styled('div')`
  display: flex;
  align-items: center;
`;
