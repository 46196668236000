/* eslint-disable no-undef */
import React, { memo, useEffect, useCallback } from 'react';

import PropTypes from 'prop-types';

const SalesCloudLiveChat = ({ s }) => {
  const handleError = useCallback((oError) => {
    throw new URIError(`The script ${oError.target.src} is not accessible.`);
  }, []);

  const initESW = useCallback(
    (gslbBaseURL) => {
      embedded_svc.settings.displayHelpButton = false;
      embedded_svc.settings.language = 'pt-BR';

      embedded_svc.settings.defaultMinimizedText = 'Você já tem atendimento em andamento';
      embedded_svc.settings.disabledMinimizedText =
        '<b>Chat</b> - Seg a Qui das 09h:00 às 18h:00<br />- Sex das 09h:30 às 18h:00';

      embedded_svc.settings.loadingText = 'Carregando';
      embedded_svc.settings.storageDomain = 'printi.com.br';

      embedded_svc.settings.directToButtonRouting = (prechatFormData) => {
        if (prechatFormData[3].value === 'Atendimento') {
          return s.button_id.service;
        }

        if (prechatFormData[3].value === 'Qualidade') {
          return s.button_id.quality;
        }

        if (prechatFormData[3].value === 'Ocorrência de arquivo') {
          return s.button_id.file_occurrence;
        }

        return s.button_id.sales; // default "Orçamento/Informações sobre produtos"
      };

      /* eslint quote-props: ["error", "consistent"] */
      embedded_svc.settings.extraPrechatInfo = [
        {
          entityName: 'Contact',
          showOnCreate: true,
          linkToEntityName: 'Case',
          linkToEntityField: 'ContactId',
          saveToTranscript: 'ContactId',
          entityFieldMaps: [
            {
              fieldName: 'FirstName',
              label: 'Primeiro Nome',
              doFind: false,
              isExactMatch: false,
              doCreate: false,
            },
            {
              fieldName: 'LastName',
              label: 'Sobrenome',
              doFind: false,
              isExactMatch: false,
              doCreate: false,
            },
            {
              fieldName: 'Email',
              label: 'Email',
              doFind: true,
              isExactMatch: true,
              doCreate: false,
            },
          ],
        },
        {
          entityName: 'Lead',
          showOnCreate: true,
          linkToEntityName: 'Case',
          linkToEntityField: 'Lead__c',
          saveToTranscript: 'LeadId',
          entityFieldMaps: [
            {
              fieldName: 'FirstName',
              label: 'Primeiro Nome',
              doFind: false,
              isExactMatch: false,
              doCreate: true,
            },
            {
              fieldName: 'LastName',
              label: 'Sobrenome',
              doFind: false,
              isExactMatch: false,
              doCreate: true,
            },
            {
              fieldName: 'Email',
              label: 'Email',
              doFind: true,
              isExactMatch: true,
              doCreate: true,
            },
          ],
        },
        {
          entityName: 'Case',
          showOnCreate: true,
          saveToTranscript: 'CaseId',
          entityFieldMaps: [
            {
              fieldName: 'prechat_Reason__c',
              label: 'Reason',
              doFind: false,
              isExactMatch: false,
              doCreate: true,
            },
            {
              fieldName: 'Status',
              label: 'Status',
              doFind: false,
              isExactMatch: false,
              doCreate: true,
            },
            {
              fieldName: 'Origin',
              label: 'Origin',
              doFind: false,
              isExactMatch: false,
              doCreate: true,
            },
          ],
        },
      ];

      embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      embedded_svc.settings.entryFeature = 'LiveAgent';

      embedded_svc.init(s.salesforce_url, s.community_url, gslbBaseURL, s.organization_id, s.embedded_service_name, {
        baseLiveAgentContentURL: s.base_live_agent_content_url,
        deploymentId: s.deployment_id,
        buttonId: s.button_id.default, // default "Default"
        baseLiveAgentURL: s.base_live_agent_url,
        eswLiveAgentDevName: s.esw_live_agent_dev_name,
        isOfflineSupportEnabled: false,
      });
    },
    [s],
  );

  const salesforceLiveChatInsertion = useCallback(() => {
    if (!window.embedded_svc) {
      if (!document.getElementById('printi-script')) {
        const pScript = document.createElement('script');
        pScript.id = 'printi-script';
        pScript.type = 'text/javascript';
        pScript.onerror = handleError;
        pScript.onload = () => {
          initESW(null);
        };
        document.body.appendChild(pScript);
        pScript.src = s.embedded_service_url;
      } else {
        initESW(null);
      }
    } else {
      initESW('https://service.force.com');
    }
  }, [handleError, initESW, s]);

  const hideLiveChat = useCallback(() => {
    if (document.querySelectorAll('.embeddedServiceHelpButton').length > 0) {
      document.querySelectorAll('.embeddedServiceHelpButton')[0].style.display = 'none';
    }
  }, []);

  useEffect(() => {
    salesforceLiveChatInsertion();

    return () => {
      hideLiveChat();
    };
  }, [salesforceLiveChatInsertion, hideLiveChat]);

  const css = `
    .embeddedServiceHelpButton .helpButton {
      width: auto !important;
    }

    .embeddedServiceHelpButton .helpButton .uiButton {
      background-color: #0050e3;
      font-family: "Arial", sans-serif;
      max-width: none;
      width: auto;
    }

    .embeddedServiceHelpButton .helpButton .uiButton:focus {
      outline: 1px solid #0050e3;
    }

    .embeddedServiceSidebar .helpButton {
      max-width: none;
      width: auto;
    }
  `;

  return (
    <>
      <style>{css}</style>
    </>
  );
};

SalesCloudLiveChat.propTypes = {
  s: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    embedded_service_name: PropTypes.string.isRequired,
    salesforce_url: PropTypes.string.isRequired,
    embedded_service_url: PropTypes.string.isRequired,
    community_url: PropTypes.string.isRequired,
    base_live_agent_content_url: PropTypes.string.isRequired,
    base_live_agent_url: PropTypes.string.isRequired,
    esw_live_agent_dev_name: PropTypes.string.isRequired,
    organization_id: PropTypes.string.isRequired,
    deployment_id: PropTypes.string.isRequired,
    button_id: PropTypes.shape({
      default: PropTypes.string.isRequired,
      sales: PropTypes.string.isRequired,
      service: PropTypes.string.isRequired,
      quality: PropTypes.string.isRequired,
      file_occurrence: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default memo(SalesCloudLiveChat);
