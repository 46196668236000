import React, { useEffect, useState } from 'react';

import { Text } from '@printi/printi-components';

const HubspotContactForm = () => {
  const [loading, setLoading] = useState(true);

  const HandleLoadHubspot = () => {
    const hubspotForm = document.getElementById('hubspot-form');

    if (!hubspotForm) {
      const script = document.createElement('script');
      script.id = 'hubspot-form';
      script.setAttribute('type', 'text/javascript');
      script.src = '//js.hsforms.net/forms/embed/v2.js';
      document.head.append(script);

      script.addEventListener('load', () => {
        const hubscript = document.createElement('script');
        hubscript.id = 'hubspot-script';
        // eslint-disable-next-line no-undef
        hbspt.forms.create({
          region: 'na1',
          portalId: '44103013',
          formId: '7a991ac6-1211-446b-a855-44b341c4a57c',
          target: '.hubspot-4business-warpper',
        });
        setLoading(false);
      });
    } else {
      hubspotForm.remove();
      HandleLoadHubspot();
    }
  };

  useEffect(() => {
    HandleLoadHubspot();
  }, []);

  return loading ? (
    <div style={{ minHeight: '400px' }}>
      <Text>Carregando...</Text>
    </div>
  ) : (
    <div id="hubspot-4business-warpper" className="hubspot-4business-warpper">
      <Text>Carregando...</Text>
    </div>
  );
};

export default HubspotContactForm;
